import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faQuoteLeft, faTimes, faCaretRight, faSearch, faFilePdf, faFile, faFileImage, faLink, faArrowLeft, faArrowRight, faAddressCard, faNewspaper, faSitemap, faPrint, faCubes, faChevronRight, faCaretDown, faGlobe } from '@fortawesome/free-solid-svg-icons';
//import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
//import { faTwitter, faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const FontAwesome = function() {
	library.add(
		faChevronRight,
		faCubes,
		faPrint,
		faSitemap,
		faNewspaper,
		faAddressCard,
		faArrowLeft,
		faArrowRight,
		faQuoteLeft,
		faTimes,
		faCaretRight,
		faCaretDown,
		faSearch,
		faFilePdf,
		faFile,
		faFileImage,
		faLink,
		faGlobe
	)

	// Kicks off the process of finding <i> tags and replacing with <svg>
	dom.watch();
};

export default FontAwesome;
