import Cookies from 'js-cookie';

function giveConsent(cookieEl) {
    Cookies.set('accept-cookies', true, { expires: 365 });

    cookieEl.classList.add('bar-hidden');

    if (window.google_tag_manager) {
        /* eslint-disable no-undef */
        dataLayer.push({'event': 'cookie_consent_given'});
    }
}

const CookieConsent = function() {
    const cookieEl	= document.getElementById('using-cookies');
    const accept = document.getElementById('accept-cookies');

    if(!cookieEl) {
        return;
    }

    // If cookies already is accepted, trigger event.
    if(Cookies.get('accept-cookies')) {
        giveConsent(cookieEl);
    } else {
        cookieEl.classList.remove('bar-hidden');
    }

    accept.addEventListener('click', () => {
        giveConsent(cookieEl);
    });

}

export default CookieConsent;
